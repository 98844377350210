// GameUpload.js
import React from 'react';
import './GameUpload.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faGlobe, faCode, faCheckCircle, faImage, faQuestionCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, useAuth } from "@clerk/clerk-react";
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator

const API_URL = 'https://api.neocu.com/api/feed';
const IMAGE_UPLOAD_API_URL = 'https://api.neocu.com/api/image/upload';

const GameUploadModal = ({ onClose }) => {
    const [step, setStep] = useState(1);
    const [uploadType, setUploadType] = useState(null);
    const [formData, setFormData] = useState({ title: '', description: '', url: '', file_url: '', username: '' });
    const [uploadedFileUrl, setUploadedFileUrl] = useState('');
    const [uploadStatus, setUploadStatus] = useState(null);
    const [submissionResult, setSubmissionResult] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(''); // State for thumbnail URL
    const [thumbnailUploadStatus, setThumbnailUploadStatus] = useState(null); // State for thumbnail upload status
    const [thumbnailPreviewUrl, setThumbnailPreviewUrl] = useState(''); // State for thumbnail preview URL
    const [adsEnabled, setAdsEnabled] = useState(false); // State for Ads switch

    const navigate = useNavigate();
    const { userId } = useAuth();

    const handleOptionClick = (type) => {
        setUploadType(type);
        setStep(2);
    };

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
            if (step <= 2) { // If going back to step 1
                setUploadType(null);
            }
            setSubmissionResult(null);
            setThumbnailUrl(''); // Clear thumbnail URL on back
            setThumbnailUploadStatus(null); // Clear thumbnail upload status
            setThumbnailPreviewUrl(''); // Clear thumbnail preview URL
            setAdsEnabled(false); // Reset ads switch on back
        }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAdsToggle = () => {
        setAdsEnabled(!adsEnabled);
    };


    const handleSelfHostSubmit = async () => {
        setUploadStatus('submitting');
        if (!thumbnailUrl) {
            setThumbnailUploadStatus('thumbnail-required');
            return;
        }
        const data_id = uuidv4(); // Generate UUID
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: formData.title,
                    description: formData.description,
                    name: formData.url,
                    category: 'sku',
                    type: 'embed',
                    status: 'progress',
                    username: formData.username, // Include username
                    desktop_thumbnail: thumbnailUrl || 'none', // Include thumbnail URLs
                    mobile_thumbnail: thumbnailUrl || 'none',
                    rank: 1,
                    user_id: userId,
                    data_id: data_id, // Include data_id
                    ads: adsEnabled, // Include ads status
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setUploadStatus('success');
            setSubmissionResult(data.feedData); // Updated to data.feedData
            setStep(3);
        } catch (error) {
            console.error("Error submitting self-hosted game:", error);
            setUploadStatus('error');
        }
    };

    const handleCodeUpload = async (event) => {
        setUploadStatus('uploading');
        const file = event.target.files[0];
        if (!file) return;

        const formDataUpload = new FormData();
        formDataUpload.append('file', file);
        formDataUpload.append('user_id', userId || 'incognito');

        try {
            const response = await fetch(IMAGE_UPLOAD_API_URL, {
                method: 'POST',
                body: formDataUpload,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (data.isSuccess && data.data.file_url) { // Access file_url correctly
                setUploadedFileUrl(data.data.file_url);
                setFormData({ ...formData, file_url: data.data.file_url });
                setUploadStatus('uploaded');
            } else {
                setUploadStatus('upload-error');
            }
        } catch (error) {
            console.error("Error uploading code:", error);
            setUploadStatus('upload-error');
        }
    };

    const handleCodeSubmit = async () => {
         setUploadStatus('submitting');
         if (!thumbnailUrl) {
            setThumbnailUploadStatus('thumbnail-required');
            return;
        }
        const data_id = uuidv4(); // Generate UUID
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: formData.title,
                    description: formData.description,
                    name: uploadedFileUrl, // Use uploaded file URL
                    category: 'sku',
                    type: 'html',
                    status: 'progress',
                    username: formData.username, // Include username
                    desktop_thumbnail: thumbnailUrl || 'none', // Include thumbnail URLs
                    mobile_thumbnail: thumbnailUrl || 'none',
                    rank: 1,
                    user_id: userId,
                    data_id: data_id, // Include data_id
                    ads: adsEnabled, // Include ads status
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
             setUploadStatus('success');
             setSubmissionResult(data.feedData); // Updated to data.feedData
             setStep(3);
        } catch (error) {
            console.error("Error submitting code game:", error);
            setUploadStatus('error');
        }
    };


    const handleThumbnailUpload = async (event) => {
        setThumbnailUploadStatus('uploading');
        const file = event.target.files[0];
        if (!file) return;

        const formDataUpload = new FormData();
        formDataUpload.append('file', file);
        formDataUpload.append('user_id', userId || 'incognito');

        try {
            const response = await fetch(IMAGE_UPLOAD_API_URL, {
                method: 'POST',
                body: formDataUpload,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (data.isSuccess && data.data.file_url) {
                setThumbnailUrl(data.data.file_url);
                setThumbnailPreviewUrl(data.data.file_url); // Set preview URL
                setThumbnailUploadStatus('uploaded');
            } else {
                setThumbnailUploadStatus('upload-error');
            }
        } catch (error) {
            console.error("Error uploading thumbnail:", error);
            setThumbnailUploadStatus('upload-error');
        }
    };


    return (
        <div className="game-upload-modal-overlay" onClick={onClose}>
            <div className="game-upload-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="game-upload-modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>

                {step === 1 && (
                    <>
                        <h2 className="game-upload-modal-title">Game Upload</h2>
                        <div className="game-upload-options">
                            <div className="game-upload-option" onClick={() => handleOptionClick('self-host')}>
                                <FontAwesomeIcon icon={faGlobe} className="game-upload-option-icon" style={{ color: '#f60' }} />
                                <div className="game-upload-option-text">
                                    <h3>Self-Host</h3>
                                    <p>Provide a URL where your game lives.</p>
                                </div>
                            </div>
                            <div className="game-upload-option" onClick={() => handleOptionClick('code')}>
                                <FontAwesomeIcon icon={faCode} className="game-upload-option-icon" style={{ color: '#f60' }} />
                                <div className="game-upload-option-text">
                                    <h3>Code</h3>
                                    <p>Upload code straight into the Neocu platform.</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {step === 2 && uploadType === 'self-host' && (
                    <>
                        <button className="game-upload-modal-back-button" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <h2 className="game-upload-modal-title">Self-Host</h2>
                        <div className="game-upload-form">
                            <input type="text" name="title" placeholder="Title" className="game-upload-input" onChange={handleInputChange} required />
                            <textarea name="description" placeholder="Description" className="game-upload-textarea" onChange={handleInputChange} required></textarea>
                            <input type="url" name="url" placeholder="URL" className="game-upload-input" onChange={handleInputChange} required />
                            <input type="text" name="username" placeholder="Public Username" className="game-upload-input" onChange={handleInputChange} required />
                            <div className="game-upload-ads-switch">
                                <label htmlFor="ads-switch" className="game-upload-ads-label">Ads:
                                    <FontAwesomeIcon icon={faQuestionCircle} className="ads-tooltip-icon" title="Ads for monetization will appear before and after your game"/>
                                </label>
                                <div className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        className="toggle-switch-checkbox"
                                        name="ads-switch"
                                        id="ads-switch"
                                        checked={adsEnabled}
                                        onChange={handleAdsToggle}
                                    />
                                    <label className="toggle-switch-label" htmlFor="ads-switch">
                                        <span className={`toggle-switch-inner ${adsEnabled ? 'toggle-switch-active' : ''}`}></span>
                                        <span className="toggle-switch-switch"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="game-upload-file-upload">
                                <input type="file" id="thumbnailUpload" className="game-upload-file-input" onChange={handleThumbnailUpload} disabled={thumbnailUploadStatus === 'uploading'} required />
                                <label htmlFor="thumbnailUpload" className="game-upload-file-label">
                                    {thumbnailUploadStatus === 'uploading' ? 'Uploading...' : (thumbnailUploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Thumbnail')}
                                </label>
                                {thumbnailUploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading thumbnail.</p>}
                                {thumbnailUploadStatus === 'thumbnail-required' && <p className="upload-error-message">Thumbnail is required.</p>}
                            </div>
                             {thumbnailPreviewUrl && (
                                <div className="thumbnail-preview">
                                    <img src={thumbnailPreviewUrl} alt="Thumbnail Preview" style={{maxWidth: '50px', height: 'auto'}} />
                                </div>
                            )}
                            <button className="game-upload-submit-button" onClick={handleSelfHostSubmit} disabled={uploadStatus === 'submitting' || thumbnailUploadStatus !== 'uploaded' && !thumbnailPreviewUrl}>
                                {uploadStatus === 'submitting' ? 'Submitting...' : 'Add'}
                            </button>
                            {uploadStatus === 'error' && <p className="upload-error-message">Error submitting game. Please try again.</p>}
                        </div>
                    </>
                )}

                {step === 2 && uploadType === 'code' && (
                    <>
                        <button className="game-upload-modal-back-button" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <h2 className="game-upload-modal-title">Code Upload</h2>
                        <div className="game-upload-form">
                            <input type="text" name="title" placeholder="Title" className="game-upload-input" onChange={handleInputChange} required />
                            <textarea name="description" placeholder="Description" className="game-upload-textarea" onChange={handleInputChange} required></textarea>
                            <input type="text" name="username" placeholder="Public Username" className="game-upload-input" onChange={handleInputChange} required />
                            <div className="game-upload-ads-switch">
                                <label htmlFor="ads-switch" className="game-upload-ads-label">Ads:
                                    <FontAwesomeIcon icon={faQuestionCircle} className="ads-tooltip-icon" title="Ads for monetization will appear before and after your game"/>
                                </label>
                                <div className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        className="toggle-switch-checkbox"
                                        name="ads-switch"
                                        id="ads-switch"
                                        checked={adsEnabled}
                                        onChange={handleAdsToggle}
                                    />
                                    <label className="toggle-switch-label" htmlFor="ads-switch">
                                        <span className={`toggle-switch-inner ${adsEnabled ? 'toggle-switch-active' : ''}`}></span>
                                        <span className="toggle-switch-switch"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="game-upload-file-upload">
                                <input type="file" id="codeUpload" className="game-upload-file-input" onChange={handleCodeUpload} disabled={uploadStatus === 'uploading'} required />
                                <label htmlFor="codeUpload" className="game-upload-file-label">
                                    {uploadStatus === 'uploading' ? 'Uploading...' : (uploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Code')}
                                </label>
                                {uploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading code.</p>}
                            </div>
                             <div className="game-upload-file-upload">
                                <input type="file" id="thumbnailUpload" className="game-upload-file-input" onChange={handleThumbnailUpload} disabled={thumbnailUploadStatus === 'uploading'} required />
                                <label htmlFor="thumbnailUpload" className="game-upload-file-label">
                                    {thumbnailUploadStatus === 'uploading' ? 'Uploading...' : (thumbnailUploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Thumbnail')}
                                </label>
                                {thumbnailUploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading thumbnail.</p>}
                                {thumbnailUploadStatus === 'thumbnail-required' && <p className="upload-error-message">Thumbnail is required.</p>}
                            </div>
                             {thumbnailPreviewUrl && (
                                <div className="thumbnail-preview">
                                    <img src={thumbnailPreviewUrl} alt="Thumbnail Preview" style={{maxWidth: '50px', height: 'auto'}} />
                                </div>
                            )}
                            <button className="game-upload-submit-button" onClick={handleCodeSubmit} disabled={uploadStatus === 'submitting' || uploadStatus !== 'uploaded' || thumbnailUploadStatus !== 'uploaded' && !thumbnailPreviewUrl}>
                                {uploadStatus === 'submitting' ? 'Submitting...' : 'Add'}
                            </button>
                            {uploadStatus === 'error' && <p className="upload-error-message">Error submitting game. Please try again.</p>}
                        </div>
                    </>
                )}
                {step === 2 && uploadType === 'thumbnail' && (
                    <>
                        <button className="game-upload-modal-back-button" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <h2 className="game-upload-modal-title">Thumbnail Upload</h2>
                        <div className="game-upload-form">
                            <div className="game-upload-file-upload">
                                <input type="file" id="thumbnailUploadStep2" className="game-upload-file-input" onChange={handleThumbnailUpload} disabled={thumbnailUploadStatus === 'uploading'} />
                                <label htmlFor="thumbnailUploadStep2" className="game-upload-file-label">
                                    {thumbnailUploadStatus === 'uploading' ? 'Uploading...' : (thumbnailUploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Thumbnail')}
                                </label>
                                {thumbnailUploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading thumbnail.</p>}
                            </div>
                             {thumbnailUploadStatus === 'uploaded' && thumbnailUrl && (
                                <div className="thumbnail-preview">
                                    <h3>Thumbnail Preview</h3>
                                    <img src={thumbnailUrl} alt="Thumbnail Preview" style={{maxWidth: '100%', height: 'auto'}} />
                                </div>
                            )}
                        </div>
                    </>
                )}


                {step === 3 && submissionResult && (
                    <div className="submission-success-container">
                        <h2 className="game-upload-modal-title">Game is Live</h2>
                        <p>Your game is now live.</p>
                        <div className="submission-actions">
                            {submissionResult._id && ( // Use data_id for public link
                                <div className="public-link-card">
                                    <p>Public Link</p>
                                    <a href={`/${submissionResult._id}`} target="_blank" rel="noopener noreferrer" className="public-link" style={{ color: '#f60' }}>
                                        {`${window.location.origin}/${submissionResult._id}`}
                                    </a>
                                </div>
                            )}
                            <button className="game-upload-button close-button" onClick={onClose}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GameUploadModal;